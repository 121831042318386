<nav class="navbar navbar-inverse navbar-fixed-top" role="navigation" style="z-index: 1040">
	<div class="container-fluid">
		<div class="navbar-header">
			<button type="button" class="navbar-toggle" (click)="changeStateNav()">
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
			</button>
			<a class="navbar-brand" href="#" style="position: relative"><img src="../assets/images/logo_poolstation.png"><!-- <div class="badge" style="position:absolute; top: 3px; right: -70px">V: 0.13</div> --></a>
		</div>

		<div class="menuBack" (click)="changeStateNav()" [ngClass]="stateNav ? 'menuBackOn' : 'menuBackOff'"></div>
		<div id="mySidenav" class="sidenav">
			<div class="wrap-250">

				<span *ngIf="loggedUser" class="dropdown" routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:
				false}">
					<a href="#" class="dropdown-toggle drop-a" data-toggle="dropdown">
						<img [src]="'data:' + loggedUser.avatar" onError="this.src = '/assets/images/avatars/default.jpg'" class="avatar img-circle img-responsive">{{ 'HOLA' | translate}}, {{ loggedUser.nombre  }}<span class="caret"></span>
				</a>
				<ul class="dropdown-menu cuenta">
					<li routerLinkActive="active" [routerLink]="['/me']" [routerLinkActiveOptions]="{exact: false}"><a href="javascript::{}">{{ "MI_CUENTA" | translate  }}</a></li>
					<li><a href="javascript::{}" [routerLink]="['/me/sessions']">{{ 'MIS_SESIONES' | translate }}</a></li>
					<li (click)="logout()"><a href="javascript::{}">{{ 'DESCONECTAR' | translate  }}</a></li>
				</ul>
				</span>
				<span *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]="['/version']">{{ 'VERSION' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]="['/advanced']">{{ 'AVANZADO' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).ADMIN || loggedUser?.id_role == (''|roles).PROPIETARIO || loggedUser?.id_role ==
				(''|roles).PROFESIONAL ||
				loggedUser?.id_role
				== (''|roles).QC || loggedUser?.id_role == (''|roles).SAT" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]="['/devices']">{{ "OPERACIONES_DISPOSITIVOS" | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).PROPIETARIO || loggedUser?.id_role == (''|roles).PROFESIONAL" routerLinkActive="active"
						[routerLinkActiveOptions]="{exact: false}">
					<a [routerLink]="['/messages']" [ngClass]="{'messagesLink' : totalPending !== 0}">
						{{ 'MENSAJES' | translate | uppercase }}
						<span *ngIf="totalPending !== 0" class="badge badge-danger lateralBadge">{{ totalPending }}</span>
				</a>
				</span>
				<span *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/users']">{{ 'USUARIOS' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).PROFESIONAL" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/clients']">{{ 'CLIENTES' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).PROPIETARIO" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/professionals']">{{ 'PROFESIONALES' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/stats']">{{ 'ESTADISTICAS' | translate | uppercase }}</a></span>
				<span *ngIf="loggedUser" class="dropdown">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ 'IDIOMA' | translate }}: {{ language | translate }}<span class="caret"></span></a>
					<ul class="dropdown-menu idioma">
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Castellano'" href="#">{{ 'ESPANYOL' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Frances'" href="#">{{ 'FRANCES' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Ingles'" href="#">{{ 'INGLES' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Italiano'" href="#">{{ 'ITALIANO' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Aleman'" href="#">{{ 'ALEMAN' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Portugues'" href="#">{{ 'PORTUGUES' | translate }}</a></li>
          				<li><a role="menuitem" tabindex="-1" (click)="language = 'Holandes'" href="#">{{ 'NEERLANDES' | translate }}</a></li>
					</ul>
				</span>

				<div class="bot">
					<div class="wrapOverflow">
						<!-- <a href="javascript:{}" [routerLink]="['/docs', 'walkthrough']">{{ 'PASEO_POOLSTATION' | translate }}</a> -->
						<a href="javascript:{}" [routerLink]="['/docs', 'thanks']">{{ 'AGRADECIMIENTOS' | translate }}</a>
						<a href="javascript:{}" [routerLink]="['/docs', 'legal']">{{ 'AVISO_LEGAL' | translate }}</a>
						<a href="javascript:{}" [routerLink]="['/docs', 'cookies']">{{ 'POLITICA_COOKIES' | translate }}</a>
					</div>
				</div>

				<div class="text-center menuFooter">
					<div class="wrapOverflow">
						<img class="nav-logo" src="/assets/images/idegis@2x.png" alt="">
						<p [innerHtml]="'COPYRIGHT' | translate"></p>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="loggedUser" class="collapse navbar-collapse navbar-ex1-collapse">
			<ul *ngIf="loggedUser" class="nav navbar-nav navbar-right" style>
				<li *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a [routerLink]="['/version']">{{ 'VERSION' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a [routerLink]=" ['/advanced']">{{ 'AVANZADO' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).ADMIN || loggedUser?.id_role == (''|roles).PROPIETARIO || loggedUser?.id_role ==
				(''|roles).PROFESIONAL || loggedUser?.id_role == (''|roles).QC || loggedUser?.id_role == (''|roles).SAT" routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:
				false}"><a [routerLink]="['/devices']">{{ 'OPERACIONES_DISPOSITIVOS' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).PROPIETARIO || loggedUser?.id_role == (''|roles).PROFESIONAL" routerLinkActive="active"
						[routerLinkActiveOptions]="{exact: false}">
					<a [routerLink]="['/messages']" [ngClass]="{'messagesLink' : totalPending !== 0}">
						{{ 'MENSAJES' | translate | uppercase }}
						<span *ngIf="totalPending !== 0" class="badge badge-danger headBadge">{{ totalPending }}</span>
					</a>
				</li>
				<li *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/users']">{{ 'USUARIOS' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).PROFESIONAL" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/clients']">{{ 'CLIENTES' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).PROPIETARIO" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/professionals']">{{ 'PROFESIONALES' | translate | uppercase }}</a></li>
				<li *ngIf="loggedUser?.id_role == (''|roles).ADMIN" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a
						[routerLink]=" ['/stats']">{{ 'ESTADISTICAS' | translate | uppercase }}</a></li>
				<li class="dropdown">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ 'IDIOMA' | translate }}: {{ language | translate }}<span class="caret"></span></a>
					<ul class="dropdown-menu">
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Castellano'" href="#">{{ 'ESPANYOL' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Frances'" href="#">{{ 'FRANCES' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Ingles'" href="#">{{ 'INGLES' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Italiano'" href="#">{{ 'ITALIANO' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Aleman'" href="#">{{ 'ALEMAN' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Portugues'" href="#">{{ 'PORTUGUES' | translate }}</a></li>
						<li><a role="menuitem" tabindex="-1" (click)="language = 'Holandes'" href="#">{{ 'NEERLANDES' | translate }}</a></li>
					</ul>
				</li>
				<li class="dropdown profile-header-element" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
				false}">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">
						<img [src]="'data:'+loggedUser.avatar" onError="this.src = '/assets/images/avatars/default.jpg'" class="avatar img-circle img-responsive">{{ 'HOLA' | translate}}, {{ loggedUser?.nombre  }}<span class="caret"></span>
					</a>
					<ul class="dropdown-menu">
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a href="javascript::{}" [routerLink]="['/me']">{{ 'MI_CUENTA' | translate  }}</a></li>
						<li><a href="javascript::{}" [routerLink]="['/me/sessions']">{{ 'MIS_SESIONES' | translate }}</a></li>
						<li><a href="javascript::{}" (click)="logout()">{{ 'DESCONECTAR' | translate  }}</a></li>
					</ul>
				</li>
			</ul>
		</div>

	</div>
</nav>
<nav class="navbar navbar-default navbar-fixed-top navbar-breadcrumb container-fluid">
	<button *ngIf="url !== '/devices'" class="btn btn-link pull-left" (click)="goBack()">
		<i class="fa fa-angle-left fa-2x"></i>
	</button>
	<ng-content></ng-content>
	<div *ngIf="device && showIcon" class="hidden-xs" style="position: relative">
		<div class="statusIcon">
			<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
          		<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
          		<i class="fa fa-plug fa-stack-1x fa-inverse" aria-hidden="true"></i>
        	</span>
			<span>
          		{{ device.status.label | translate }}
          		<small *ngIf="device.status.value === 0" class="text-muted">{{ device.lastConnection }}</small>
        	</span>
		</div>
	</div>
</nav>
